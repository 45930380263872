<template lang="pug">
  div.master
    LoadingItem(v-if="loading")
    MainHeadItem(iconName="admin_master" title="マスタ管理")
      IconMaster

    .master_tab_wrap
      ul.master_tab
        li.departments(@click="tabSelect('departments')" :class="{'active': isActive === 'departments'}") 部署マスタ
        li.positions(@click="tabSelect('positions')" :class="{'active': isActive === 'positions'}") 役職マスタ
        li.langs(@click="tabSelect('langs')" :class="{'active': isActive === 'langs'}") 言語マスタ

    ul.master_list
      li(v-for="(data, id) in sortMaster" :key="id" :class="id")
        .master_content(v-if="isActive === id")
          ModalItem.add_new_modal(
            v-if="isOpenAddForm"
            v-scroll-lock="isOpenAddForm"
            :title="(id == 'departments' ? '部署' : id == 'positions' ? '役職' : '言語') + 'マスタ新規登録'"
            @close="reset"
            @submit="onRegister(id)"
            :isDisabled="cantRegister"
            submit="マスタ登録"
          )
            p.add_innner
              input(type="text" v-model="newMasterInput")

          table
            thead
              tr
                th(colspan="2")
                   button.add_new(type="button" @click="openAddForm()")
                    IconBase(width="15" height="15" icon-name="admin")
                      IconAdd
                    span 新規追加
                th.sort_th
                  .sort_wrap(v-if="isSortMode")
                    button.update(type="button" @click="updateSort(id)" :disabled="cantSortUpdate") 更新
                    button.cancel(type="button" @click="reset") 取消
                  .sort_wrap(v-else="isSortMode")
                    button.sort(type="button" @click="onSortMode")
                      IconBase(width="15" height="15" icon-name="admin")
                        IconSort
                      span 順序変更
            Draggable(tag="thead" handle=".sort_btn_wrap" draggable="tr" group="master" v-model="sortMaster[id]" v-bind="dragOptions" @start="drag = true" @end="drag = false" :component-data="getComponentData()")
              tr(v-for="masterData in data" :key="masterData.id" type="transition" :name="!drag ? 'flip-list' : null")
                td {{ masterData.sortId }}
                td(v-if="editId == id + '_' + masterData.id")
                  input(type="text" :id="id + '_' + masterData.id" v-model="editInput")
                td(v-else) {{ masterData.name }}
                td.sort_td
                  .sort_btn_wrap(v-if="isSortMode")
                    IconBase(width="20" height="30" icon-name="admin")
                      IconDrag
                  .btn_wrap(v-else-if="editId == id + '_' + masterData.id")
                    button.update(type="button" @click="update(id, masterData.id)" :disabled="cantUpdate") 登録
                    button.cancel(type="button" @click="reset") 取消
                  .btn_wrap(v-else)
                    button.edit(type="button" @click="setEditId(id + '_' + masterData.id , masterData.name)") 編集
                    button.delete(type="button" @click="deleteMaster(id, masterData.id)") 削除
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MainHeadItem from '@/components/MainHeadItem.vue'
import IconBase from '@/components/IconBase.vue'
import IconMaster from '@/components/icons/IconMaster.vue'
import IconAdd from '@/components/icons/IconAdd.vue'
import IconSort from '@/components/icons/IconSort.vue'
import IconDrag from '@/components/icons/IconDrag.vue'
import LoadingItem from '@/components/LoadingItem'
import ModalItem from '@/components/ModalItem.vue'
import Draggable from 'vuedraggable'
export default {
  name: 'AdminHomeView',
  metaInfo: {
    title: '【管理】マスタ管理画面',
  },
  components: {
    MainHeadItem,
    IconBase,
    IconMaster,
    IconAdd,
    IconSort,
    IconDrag,
    LoadingItem,
    ModalItem,
    Draggable,
  },
  data() {
    return {
      loading: false,
      isActive: 'departments',
      editId: '',
      oldName: '',
      editInput: '',
      cantUpdate: true,
      cantRegister: true,
      cantSortUpdate: true,
      isOpenAddForm: false,
      isSortMode: false,
      drag: false,
      newMasterInput: '',
      sortMaster: {},
    }
  },
  watch: {
    newMasterInput: function (val) {
      this.cantRegister = val.length <= 0
    },
    editInput: function (val) {
      this.cantUpdate = !(val !== this.oldName && val.length > 0)
    },
  },
  computed: {
    ...mapState('masterModule', ['master']),
    ...mapState('usersModule', ['users']),

    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  created() {
    // 部署, 役職、言語の順で格納
    this.sortMaster = this.sortMasterData()
  },
  methods: {
    ...mapActions('masterModule', ['updateMasterField', 'deleteMasterField']),
    sortMasterData() {
      let sortMaster = {}
      Object.keys(this.master).forEach((id) => {
        sortMaster[id] = []
        Object.keys(this.master[id]).forEach((masterId) => {
          let masterData = this.master[id][masterId]
          masterData.id = masterId
          sortMaster[id].push(masterData)
        })
        sortMaster[id].sort((a, b) => a.sortId - b.sortId)
      })
      return {
        departments: sortMaster['departments'],
        positions: sortMaster['positions'],
        langs: sortMaster['langs'],
      }
    },
    getNewDataFormat(masterName) {
      let sortIdList = []
      const masterData = this.master[masterName]
      const masterIdList = Object.keys(masterData)
      const aryMax = function (a, b) {
        return Math.max(a, b)
      }
      masterIdList.forEach((masterId) => {
        sortIdList.push(masterData[masterId].sortId)
      })
      return {
        newId: parseInt(masterIdList.reduce(aryMax)) + 1,
        newSortId: parseInt(sortIdList.reduce(aryMax)) + 1,
      }
    },
    reset() {
      this.editId = ''
      this.oldName = ''
      this.editInput = ''
      this.newMasterInput = ''
      this.cantUpdate = true
      this.cantRegister = true
      this.cantSortUpdate = true
      this.isOpenAddForm = false
      this.isSortMode = false
      this.drag = false
      this.loading = false
      Object.keys(this.sortMaster).forEach((masterType) => {
        this.sortMaster[masterType].sort((a, b) => a.sortId - b.sortId)
      })
    },
    openAddForm() {
      this.isOpenAddForm = true
    },
    async onRegister(masterName) {
      this.loading = true
      let format = this.getNewDataFormat(masterName)
      let addData = {}
      addData[format.newId] = {
        name: this.newMasterInput,
        sortId: format.newSortId,
      }
      await this.updateMasterField({
        masterName: masterName,
        updateData: addData,
      })
      this.sortMaster = this.sortMasterData()
      this.reset()
    },
    handleChange() {
      this.cantSortUpdate = false
    },
    getComponentData() {
      return {
        on: {
          change: this.handleChange,
        },
      }
    },
    tabSelect(type) {
      this.reset()
      this.isActive = type
    },
    setEditId(editId, oldName) {
      this.editId = editId
      this.oldName = oldName
      this.editInput = oldName
    },
    onSortMode() {
      this.isSortMode = true
    },
    async updateSort(id) {
      if (confirm('表示順を変更しますか？')) {
        this.loading = true
        let updateData = {}
        this.sortMaster[id].forEach((data, idx) => {
          updateData[data.id] = {
            name: data.name,
            sortId: parseInt(idx) + 1,
          }
        })
        await this.updateMasterField({
          masterName: id,
          updateData: updateData,
        })
        this.sortMaster = this.sortMasterData()
        this.reset()
      }
    },
    async update(masterName, editId) {
      if (
        confirm(
          '【' +
            this.master[masterName][editId].name +
            '】から【' +
            this.editInput +
            '】へ変更してもよろしいですか？',
        )
      ) {
        this.loading = true
        const updateData = {}
        updateData[editId] = {
          name: this.editInput,
          sortId: this.master[masterName][editId].sortId,
        }
        await this.updateMasterField({
          masterName: masterName,
          updateData: updateData,
        })
        this.sortMaster = this.sortMasterData()
        this.reset()
      }
    },
    async deleteMaster(masterName, id) {
      let targetUsers = []
      Object.keys(this.users).forEach((userId) => {
        const account = this.users[userId]
        let targetId = null
        if (masterName == 'departments') {
          targetId = account.departmentId
        } else if (masterName == 'positions') {
          targetId = account.positionId
        }
        if (targetId !== null) {
          if (targetId == id) {
            targetUsers.push(account)
          }
        } else {
          if (account.langs.includes(parseInt(id))) {
            targetUsers.push(account)
          }
        }
      })
      if (targetUsers.length > 0) {
        alert(
          targetUsers.length +
            '名のユーザに【' +
            this.master[masterName][id].name +
            '】が設定されているため削除できません。\n※削除するためには、削除したいマスタデータに紐づくユーザの情報を変更してください。',
        )
      } else {
        if (
          confirm(
            '【' +
              this.master[masterName][id].name +
              '】をマスタデータから削除してもよろしいですか？',
          )
        ) {
          this.loading = true
          await this.deleteMasterField({
            masterName: masterName,
            targetId: id,
          })
          this.sortMaster = this.sortMasterData()
          this.reset()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.master {
  .master_tab_wrap {
    position: sticky;
    background: #fff;
    top: 0;
    z-index: 15;
    .master_tab {
      max-width: 1020px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      li {
        width: calc(100% / 3);
        padding: 15px 0;
        text-align: center;
        cursor: pointer;
        transition: all 0.5s ease;
        text-decoration: none;
        border-bottom: 3px solid #eaeaea;
        &.active {
          border-color: #88cabe;
          color: #88cabe;
          font-weight: bold;
        }
      }
    }
  }
  .add_new_modal {
    .add_innner {
      margin: 20px auto;
      padding: 0 20px;
    }
  }
  .master_list {
    max-width: 850px;
    margin: 30px auto;
    padding: 0 15px;
    table {
      text-align: left;
      width: calc(100% - 10px);
      line-height: 1.5;
      font-size: 14px;
      th {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #d3d3d3;
        vertical-align: middle;
        button {
          display: flex;
          align-items: center;
          line-height: 1;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;
          padding: 8px 15px;
          background: #edad83;
          color: #fff;
          font-size: 13px;
          &.sort {
            background: #87a2cb;
          }
          span {
            margin: 0 0 0 5px;
            white-space: nowrap;
          }
        }
      }
      .sort_th {
        padding: 0;
        .sort_wrap {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          button {
            white-space: nowrap;
            &:last-child {
              margin-left: 10px;
            }
            font-size: 13px;
            &.update {
              transition: background-color 0.5s ease;
              background-color: #88cabe;
              background-image: linear-gradient(
                315deg,
                #6bc5b3 0%,
                #88cabe 74%
              );
              &:disabled {
                background-color: #c3bfbf;
                background-image: linear-gradient(
                  315deg,
                  #c3bfbf 0%,
                  #c3c3c3 74%
                );
                cursor: inherit;
              }
            }
            &.cancel {
              border: 1px solid #88cabe;
              color: #88cabe;
              background: #fff;
            }
          }
        }
      }
      td {
        padding: 7px 10px;
        text-align: left;
        border-bottom: 1px solid #d3d3d3;
        vertical-align: middle;
        background: rgba(255, 255, 255, 0.823);
        &:nth-child(1) {
          width: 5%;
          text-align: center;
        }
        &:nth-child(2) {
          width: 65%;
        }
        &:nth-child(3) {
          width: 30%;
          text-align: center;
        }
        &.sort_td {
          text-align: right;
        }
        .sort_btn_wrap {
          display: inline-block;
          cursor: move;
          padding: 5px 10px;
        }
        .btn_wrap {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          white-space: nowrap;
          button {
            cursor: pointer;
            border-radius: 5px;
            padding: 7px 10px;
            border: 1px solid #88cabe;
            color: #88cabe;
            font-size: 13px;
            min-width: calc(50% - 40px);
            &:last-child {
              margin: 0 0 0 10px;
            }
            &.delete {
              color: #787878;
              border: 1px solid #c3bfbf;
            }
            &.update {
              background-color: #88cabe;
              background-image: linear-gradient(
                315deg,
                #6bc5b3 0%,
                #88cabe 74%
              );
              color: #fff;
            }
            &:disabled {
              color: #fff;
              border: 1px solid #d1d1d1;
              background-color: #c3bfbf;
              background-image: linear-gradient(
                315deg,
                #c3bfbf 0%,
                #c3c3c3 74%
              );
              cursor: not-allowed;
            }
          }
        }
      }
      tr:last-child td {
        border-bottom: none;
      }
    }
  }
  input {
    line-height: 1.2;
    font-size: 13px;
    letter-spacing: 2px;
    padding: 10px 15px;
    display: block;
    width: calc(100% - 30px);
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    background: #fff;
    resize: none;
  }
}
</style>
